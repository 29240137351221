<template>
  <n-flex vertical>
    <n-flex class="app-head-flex" justify="space-between" align="center">
      <n-flex>
        <n-image width="120" height="120" :src="icon"/>
        <n-flex vertical>
          <p class="app-title">{{ name }}</p>
          <p>{{ text }}</p>
        </n-flex>
      </n-flex>
      <n-button @click="toDownload">点击下载</n-button>
    </n-flex>
    <n-flex :wrap="false" :size="16">
      <n-image v-for="image in storeImages" v-bind:key="image" width="100%" :src="image"/>
    </n-flex>
  </n-flex>
</template>
<script>
import {NButton, NFlex, NImage} from "naive-ui";

export default {
  name: 'AppShowItem',
  components: {NButton, NImage, NFlex},
  props: ['icon', 'name', 'text', 'downloadUrl', 'storeImages'],
  methods: {
    toDownload
  },
  setup(props) {
    console.log(props.storeImages)
  }
}

function toDownload() {
  window.open(this.downloadUrl, '_blank')
}
</script>
<style>

.app-head-flex {
  padding-top: 24px;
}

.app-title {
  font-size: 16px;
  font-weight: bold;
}
</style>